<template>
  <div style="width: 100%;">
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="eventHandler"
          :loading="loading"
          @changeStatus="changeStatus($event)"
          @fetchEventHandlers="fetchEventHandlers($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './EventHandlerDatatable.vue'
import {
  getTriggerEventHandlersUsingGET as getEventHandlers,
  disableTriggerEventHandlerUsingGET as disableEventHandler,
  enableTriggerEventHandlerUsingGET as enableEventHandler
} from '@/utils/api'
import { mapActions } from 'vuex'
export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      eventHandler: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      }) 
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    fetchEventHandlers(options) {
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options?.options?.sortDesc && options.options.sortDesc.length > 0) obj.orderDirection = options.options.sortDesc[0] ? 'DESC' : 'ASC'

      if (options && options.options && options.options.sortBy && options.options.sortBy.length > 0) {
        obj.orderField = options.options.sortBy[0]
      }

      if (options && options.listenType) {
        obj.listenType = options.listenType
      }

      if (options && options.listenStatus) {
        obj.listenStatus = options.listenStatus
      }

      if (options && options.listenProcessId) {
        obj.listenProcessId = options.listenProcessId
      }

      if (options && options.listenAllProcesses) {
        obj.listenAllProcesses = options.listenAllProcesses
      }

      if (options && options.search && options.search.length > 1) {
        obj.name = options.search
      }

      if (options && options.processId) {
        obj.processId = options.processId
      }

      if (options && options.service) {
        obj.service = options.service
      }

      if (options && options.activeOnly) obj.status = 'ACTIVE'

      getEventHandlers(obj)
        .then((res) => {
          this.eventHandler = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          }) 
          console.log(error)
        })
    },
    async changeStatus (item) {
      try {
        const res = item.status === 'ACTIVE' ? await disableEventHandler({ id: item.id }) : await enableEventHandler({ id: item.id })

        if (res.status !== 200) {

          this.addSnackbar({
            message:  this.$lang.errors.eventHandlerStatusUpdate,
            timeout: 5000,
            color: 'error'
          }) 

          return
        }
        const successMessage = item.status !== 'ACTIVE' ? this.$lang.success.eventHandlerActivated : this.$lang.success.eventHandlerDeactivated

        this.addSnackbar({
          message:  successMessage,
          timeout: 5000,
          color: 'success'
        }) 
        const changedItem = this.eventHandler.items.find((x) => x.id === item.id)

        if (changedItem) this.eventHandler.items.find((x) => x.id === item.id).status = res.data.data.status

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        }) 
      }
    }
  }
}
</script>
